<template>
  <div class="row mt-5">
    <div class="col-md-12 mb-5" style="overflow: scroll">
      <b-table striped hover
               :fields="fields"
               :per-page="perPage"
               selectable id="table"
               :current-page="currentPage"
               :items="schedule.length ? schedule : sched" class="size-table">
        <template #cell(index)="data">
          <div class="form-checksss cks">
            <label >
              {{data.index + 1 }}
            </label>
          </div>
        </template>
        <template #cell(loan_schedule.schddate)="data">
          {{new Date(data.item.loan_schedule.schddate).toDateString()}}
        </template>
        <template #cell(loan_schedule.paid_yn)="data">
          <input type="checkbox"
                 id="scales" name="scales" disabled
                 :checked="data.item.loan_schedule.paid_yn"
          >
        </template>
        <template #cell(requested_amt)="data">
          {{ formatters(data.item.requested_amt)}}
        </template>
        <template #cell(monthlypayment)="data">
          {{ formatters(data.item.monthlypayment) }}
        </template>
        <template #cell(loan_schedule.schedule_principal)="data">
          {{ formatters(data.item.loan_schedule.schedule_principal)}}
        </template>
        <template #cell(loan_schedule.schedule_interest)="data">
          {{ formatters(data.item.loan_schedule.schedule_interest)}}
        </template>
        <template #cell(loan_schedule.install_amount)="data">
          {{ formatters(data.item.loan_schedule.install_amount)}}
        </template>
      </b-table>
      <div class="rows">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "LoanCharts",
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      items: [],
      fields: [
        {
          key: 'index', label: 'S/N'
        },
        {
          key: 'loan_schedule.schddate',
          label: 'Schedule Date',
        },
        {
          key: 'requested_amt',
          label: 'Loan Amount',
        },
        {
          key: 'monthlypayment',
          label: 'Monthly Payment',
        },
        {
          key: 'loan_schedule.schedule_principal',
          label: 'Schedule Payment',
        },
        {
          key: 'loan_schedule.schedule_interest',
          label: 'Schedule Interest',
        },
        {
          key: 'loan_schedule.paid_yn',
          label: 'Paid',
        },
        {
          key: 'loan_schedule.install_amount',
          label: 'Installment Amount',
        },
      ],
    }
  },
  props: {
    loans: {
      type: [Object, Array],
      required: false,
    }
  },
  computed: {
    ...mapGetters({
      loan: 'loan/loans'
    }),
    sched () {
      const ln = []
      if (this.loan && this.loan.length) {
        const hst = this.loan[0]

        hst.loan_schedule.map(value => {
          ln.push({
            mature_date: hst.mature_date,
            loanbal: hst.loanbal,
            loaninterest: hst.loaninterest,
            prelim_id: hst.prelim_id,
            requested_amt: hst.requested_amt,
            monthlypayment: hst.monthlypayment,
            loan_schedule: value,
          })
        })
      }
      return ln
    },
    schedule() {
      const ln = []
      if (this.loans && this.loans.loan_schedule) {
        this.loans.loan_schedule.map(value => {
          ln.push({
            mature_date: this.loans.mature_date,
            loanbal: this.loans.loanbal,
            loaninterest: this.loans.loaninterest,
            prelim_id: this.loans.prelim_id,
            requested_amt: this.loans.requested_amt,
            monthlypayment: this.loans.monthlypayment,
            loan_schedule: value,
          })
        })
      }
      return ln;
    },
    rows() {
      return this.schedule.length
    }
  }
}
</script>

<style scoped>

</style>
