<template>
    <admin-default v-slot:admin>
        <div class="application" v-if="partner">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Submit Credentials</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{user.name}}</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mt-5">
                            <label for="identification" class="form__label">Means of Identification</label>
                            <select class="form-control form__input margin-bottom" id="identification" disabled>
                                <option >{{ partner.document_client_id }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="group" class="form__label">Corporate/Group</label>
                            <select class="form-control form__input form__input--small" id="group" disabled>
                                <option>Individual</option>
                                <option>2</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="date" class="form__label">Date</label>
                            <input :value="partner.date_expiry" type="date" class="form-control form__input margin-bottom" id="date" disabled>
                        </div>
                    </div>
                </div>

                <label class="form__label form__label-big">ID Card No.</label>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Issue" class="form__label form__label-small">Issue Date</label>
                            <input disabled type="date" class="form-control form__input form__input--small" id="Issue" :value="partner.date_release">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Expiry" class="form__label form__label-small">Expiry Date</label>
                            <input disabled type="date" class="form-control form__input margin-bottom" id="Expiry" :value="partner.date_expiry">
                        </div>
                    </div>
                </div>

<!--                <h1 class="form__label">Upload File</h1>-->
<!--                <div class="upload-box">-->
<!--&lt;!&ndash;                    <img src="images/carbon_cloud-upload.svg" alt="upload icon" class="upload-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                    <h1 class="form__label upload-text">Drag as many images as possible in here</h1>&ndash;&gt;-->
<!--&lt;!&ndash;                  <dropzone-component></dropzone-component>&ndash;&gt;-->
<!--                </div>-->

                <div class="button--box">
                    <button type="button" class="btn button button-cta cta">Save</button>
                    <button type="button" class="btn button btn-outline-primary button-outline cta">Reset</button>
                </div>
            </div>
        </div>
    </admin-default>
</template>

<script>
import {mapGetters} from 'vuex'
import AdminDefault from "../navigate/AdminDefault";
// import DropzoneComponent from "./pages/DropzoneComponent";
export default {
  name: "Credentials",
  components: { AdminDefault},
  computed: {
    ...mapGetters({
      partner: 'auth/partner'
    })
  }
}
</script>

<style scoped>

</style>