<template>
  <admin-default v-slot:admin>
    <div class="application">
      <div class="container container-resize">
        <div class="row border-bottom pb-5">
          <div class="col-md-9"><h1 class="application__secondary--title">Loan schedule Report</h1></div>
          <div class="col-6 col-md-3">
            <h1 class="application__main--title">Welcome,</h1>
            <h1 class="application__tertiary--title">{{ user.name }}</h1>
          </div>
        </div>
      </div>

      <div class="container container-resize">
        <div class="row mt-5 mb-4">
          <div class="col-md-12">
            <b-form-group id="input-group-3" label="Select Loan Type:" label-for="input-3">
              <b-form-select v-model="selected"
                             id="input-3"
                             :options="schedule" size="lg"
                             @change="ChangeLoans($event)"
                             class="mt-3"></b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-3" v-if="selectedLoans">
          <div class="col-md-6 mt-1 mb-1">
            <div class="report-loans">
              <div class="left">
                <p>Loan Amount</p>
              </div>
              <div class="right">
                <p>{{ formatters(selectedLoans.requested_amt) }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1 mb-1">
            <div class="report-loans">
              <div class="left">
                <p>Monthly Repayment</p>
              </div>
              <div class="right">
                <p>{{ formatters(selectedLoans.monthlypayment) }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1 mb-1">
            <div class="report-loans">
              <div class="left">
                <p>Interest on loan</p>
              </div>
              <div class="right">
                <p>{{ formatters(selectedLoans.loaninterest) }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1 mb-1">
            <div class="report-loans">
              <div class="left">
                <p>Scheduled number of payment </p>
              </div>
              <div class="right">
                <p>{{ schedule_loans.length }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1 mb-1">
            <div class="report-loans">
              <div class="left">
                <p>Start date of loan</p>
              </div>
              <div class="right">
                <p>{{ new Date(selectedLoans.loan_schedule[0].schddate).toDateString() }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-1 mb-1">
            <div class="report-loans">
              <div class="left">
                <p>Last month of loan</p>
              </div>
              <div class="right">
                <p>{{ new Date(selectedLoans.mature_date).toDateString() }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h2>Loan schedule details</h2>
          </div>
          <div class="col-md-12 mb-5">
            <loan-charts :loans="selectedLoans"></loan-charts>
          </div>
        </div>
      </div>
    </div>
  </admin-default>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex'
import AdminDefault from "../navigate/AdminDefault";
import LoanCharts from "./pages/LoanCharts";
export default {
name: "ScheduleReport",
  components: {LoanCharts, AdminDefault},
  data() {
    return {
      selected: null,
      selectedLoans: this.loans && this.loans.length ? this.loans[0] : null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loans: 'loan/loans',
      payments: 'loan/payment',
      schedule_loans: 'loan/schedule_loans',
    }),
    schedule () {
      let sch = [
        { value: null, text: ' -- Please select loan schedule type -- ' },
      ]
      if (this.loans.length) {
        this.loans.map(value => {
          sch.push({
            value: value.id,
            text: value.name,
          })
        })
      }
      return sch
    }
  },
  methods: {
    ...mapMutations({
      ScheduleLoans: 'loan/payment'
    }),
    ChangeLoans (event) {
      const found = this.loans.find(value =>  value.id === event)
      this.selectedLoans = found
    }
  },
  mounted() {
    this.ScheduleLoans(this.schedule_loans[0])
  }
}
</script>

<style scoped>

</style>
