import { render, staticRenderFns } from "./LoanCharts.vue?vue&type=template&id=37d1d236&scoped=true&"
import script from "./LoanCharts.vue?vue&type=script&lang=js&"
export * from "./LoanCharts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d1d236",
  null
  
)

export default component.exports